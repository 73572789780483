/** @jsx jsx */
import { useState } from "react"
import { toast } from "react-toastify"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from "react-redux"

import Container from "../../../Container"
import Button from "../../../Button"

import { login, getUserData } from "../../../../state/app"
import Loading from "../../../../assets/images/svg/loadingbutton.svg"

const CustomContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 110px;
  padding-bottom: 110px;

  ${({ theme }) => css`
    ${theme.queries.small} {
      padding-top: 20px;
      padding-bottom: 30px;

      > div {
        width: 100%;
      }
    }

    ${theme.queries.large} {
      width: 800px;
    }
  `}
`

const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-weight: bold;
    font-size: 30px;
    line-height: 106.2%;
    width: 210px;
    margin-bottom: 18px;

    ${theme.queries.small} {
      font-size: 23px;
      text-align: left;
      margin-bottom: 20px;
    }
  `}
`

const Description = styled.p`
  ${({ theme }) => css`
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
    color: #203760;

    ${theme.queries.small} {
      margin-bottom: 35px;
    }
  `}
`

const Input = styled.input`
  ${({ theme, error }) => css`
    border: 1px solid ${error ? theme.colors.red : theme.colors.lightGrey};
    color: ${theme.colors.blue};
    font-size: 23px;
    padding: 12px 25px;
    width: 100%;
    max-width: 400px;
    display: block;
    border-radius: 5px;
    font-size: 16px;

    & + input {
      margin-top: 10px;
    }

    &::placeholder {
      color: ${theme.colors.blue};
    }
  `}
`

const CustomButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
    text-transform: uppercase;
    height: 55px;
    position: relative;

    svg {
      position: absolute;
      top: 3px;
      right: 50%;
      transform: translateX(50%);
      height: 50px;
    }

    ${theme.queries.small} {
      font-size: 11pt;
    }
  `}
`

const CustomImage = styled(Img)`
  ${({ theme }) => css`
    width: 446px;
    height: 356px;
    margin-right: 50px;

    ${theme.queries.small} {
      width: 80px;
      height: auto;
      margin: 0 0 30px;
    }
  `}
`

const hideDesktop = ({ queries }) => css`
  ${queries.large} {
    display: none;
  }
`

const hideMobile = ({ queries }) => css`
  width: 255px;
  height: auto;

  ${queries.small} {
    display: none;
  }
`

const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red};
    font-size: 11px;
    margin-top: 5px;
    display: block;
    width: 100%;
    margin-left: 0;
  `}
`

const ConfirmEmailStep = ({
  setStep,
  phone,
  facebookCode,
  emailToConfirmation,
  goDonate,
  dispatch,
  translation,
}) => {
  const [email, setEmail] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const data = useStaticQuery(graphql`
    query {
      confirm: file(relativePath: { eq: "confirm.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const onSubmit = e => {
    e.preventDefault()
    setLoading(true)
    dispatch(
      login({
        googleCode: facebookCode.i,
        phoneNumber: phone,
        email,
      })
    )
      .then(() => {
        goDonate()
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setErrorMsg(err.response.data.Message)
      })
  }

  return (
    <CustomContainer>
      <div
        css={css`
          width: 312px;
        `}
      >
        <CustomImage
          css={hideDesktop}
          fluid={data.confirm.childImageSharp.fluid}
        />

        <Title>{translation.login.step2.blueText}</Title>
        <Description>{translation.login.step2.greyText}</Description>

        <form onSubmit={e => onSubmit(e)}>
          <Input type="text" value={emailToConfirmation} disabled />
          <Input
            type="text"
            placeholder="E-mail"
            value={email}
            onChange={e => setEmail(e.target.value)}
            css={({ colors }) =>
              css`
                ${errorMsg && `border-color: ${colors.red};`}
              `
            }
          />
          <Error>{errorMsg}</Error>
          <CustomButton disabled={isLoading} uppercase color="red">
            {isLoading ? (
              <Loading role="img" />
            ) : (
              translation.login.step2.confirmEmail
            )}
          </CustomButton>
        </form>
      </div>

      <div>
        <CustomImage
          css={hideMobile}
          fluid={data.confirm.childImageSharp.fluid}
        />
      </div>
    </CustomContainer>
  )
}

export default connect()(ConfirmEmailStep)
