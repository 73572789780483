/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Container from "../Container"

const StepsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-center: center;

    ${theme.queries.small} {
      justify-content: center;
    }
  `}
`

const Step = styled.div`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    color: ${active ? theme.colors.blue : theme.colors.formBlue};
    font-weight: bold;
    transition: ease-in 200ms;
    margin-right: 10px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 31px;
      width: 31px;
      border-radius: 50%;
      border: 3px solid ${active ? theme.colors.blue : theme.colors.formBlue};
      font-size: 12px;
      color: ${active ? theme.colors.blue : theme.colors.formBlue};
      font-weight: bold;
      margin-right: 10px;
    }

    &:last-child {
      margin: 0;
    }
  `}
`

const Steps = ({ currentStep, destination, title, translation }) => {
  const stepList = [
    translation.register,
    translation.destiny,
    destination,
    translation.method,
  ]

  return (
    <StepsContainer>
      {stepList.map((item, index) => (
        <Step key={index} active={index === currentStep}>
          <span>{index + 1}</span>
          {index === currentStep && item}
        </Step>
      ))}
    </StepsContainer>
  )
}

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  destination: PropTypes.string,
  title: PropTypes.string,
}

const Wizard = ({
  children,
  donationStep,
  destination,
  title,
  translation,
}) => (
  <Container>
    <Steps
      translation={translation}
      currentStep={donationStep}
      title={title}
      destination={destination}
    />
    {children}
  </Container>
)

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  destination: PropTypes.string,
  title: PropTypes.string,
}

export default connect(
  state => ({
    donationStep: state.app.donationStep,
  }),
  null
)(Wizard)
