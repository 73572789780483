import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const Button = styled(motion.button)`
  ${({ theme, color, uppercase }) => css`
    background: #00a1ff;
    box-shadow: 0px 14px 24px rgba(0, 161, 255, 0.2);
    border-radius: 10px;

    padding: 18px 35px;
    border: none;

    color: white;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    cursor: pointer;

    ${uppercase &&
      css`
        text-transform: uppercase;
      `}
  `}
`

export default Button
