import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { locales, routes } from "../../locales"
import { langNavigate } from "../../helpers"

const checkPath = pathname => {
  let isPath = false
  locales.forEach(lang => {
    // TODO
    if (
      process.env.GATSBY_ENV === "dev"
        ? pathname === `/${lang}/doar`
        : pathname === `/doar2/${lang}/doar`
    ) {
      isPath = true
    }
  })
  return isPath
}

const OnlyPublicRoute = ({
  component: Component,
  location,
  isLoggedIn,
  hasChurch,
  hasCampaign,
  translation,
  ...rest
}) => {
  if (isLoggedIn && !checkPath(location.pathname)) {
    setTimeout(() => {
      if (hasChurch) {
        if (hasCampaign) {
          langNavigate(6, translation.currentLang)
        } else {
          langNavigate(10, translation.currentLang)
        }
      } else {
        langNavigate(5, translation.currentLang)
      }
    }, 0)
    return null
  }

  return <Component {...rest} />
}

OnlyPublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default connect(
  state => ({
    isLoggedIn: !!state.app.user.token,
    hasChurch: !!state.app.donationDestination.id,
    hasCampaign: !!state.app.campaignDestination.id,
  }),
  null
)(OnlyPublicRoute)
